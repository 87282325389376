<template>
  <div class="teacher">
    <list-template
      :loading="loading"
      :total="total"
      :current-page="page"
      :table-data="tableData"
      :table-config="tableConfig"
      :search-config="searchConfig"
      @onSearch="onSearch"
      @onReset="search=null;searchConfig[2].options = []"
      @onChangePage="changeCurrentPage"
      @onHandle="tableHandle"
      @onExportData="onExportData"
    ></list-template>
  </div>
</template>

<script>
import ListTemplate from "@/components/pages/List.vue";

export default {
  components: {ListTemplate},
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading: true,
      // 表格搜索配置
      searchConfig: [
        {
          tag: 'select',
          placeholder: '请选择校区',
          prop: 'school_id',
          options: [],
          change: (val, search) => {
            this.search = { ...search }
          }
        },
        {
          prop: 'subject_pid',
          placeholder: '请选择科目',
          tag: 'select',
          options: [],
          label: 'subject_name',
          value: 'id',
          change: (val,search) => {
            setTimeout(() => {
              if (search.subject_id) delete search.subject_id
              this.getSubject(val)
              this.$forceUpdate()
            })
          }
        },
        {
          prop: 'subject_id',
          placeholder: '请选择学科',
          tag: 'select',
          options: [],
          label: 'subject_name',
          value: 'id',
        },
        {
          prop: 'clerk_name',
          placeholder: '搜索教师姓名'
        }
      ],
      // 表格配置
      tableConfig: [
        {
          prop: 'clerk_name',
          label: '教师姓名'
        },
        {
          prop: 'school',
          label: '校区'
        },
        {
          prop: 'subject_pname',
          label: '科目'
        },
        {
          prop: 'subject_name',
          label: '教学学科'
        },
        {
          prop: 'clerk_status',
          label: '是否在职'
        },
        {
          label: '操作',
          width: '120rem',
          handle: true,
          buttons: [{ type:"view", text:"查看" }]
        }
      ],
      search: null,
      // 当前页码
      page: 1,
      total: 0,
      // 表格中的数据
      tableData: []
    }
  },
  mounted() {
    this.getData()
    this.getOption()
    this.getConfig()
  },
  activated() {
    this.getData()
  },
  methods: {
    getConfig() {
      this.$_axios.get('/site/subject').then(res => {
        const { data: { data } } = res
        this.searchConfig[1].options = data
      })
    },
    getSubject(id) {
      const idx = this.searchConfig[1].options.findIndex(item => item.id === id)
      this.searchConfig[2].options = this.searchConfig[1].options[idx].child
    },
    getOption() {
      const obj = this.$store.getters.userInfo.school_arr
      let options = []
      Object.keys(obj).forEach(key => {
        options.push({ label: obj[key], value: key })
      })
      this.searchConfig[0].options = options
    },
    // 分页被更改
    changeCurrentPage(e) {
      this.page = e
      this.getData()
    },
    /**
     * 获取数据
     */
    getData() {
      let { search, page } = this
      this.loading = true
      this.$_axios
        .get('/teacher', { params: { ...search, page } })
        .then(res => {
          let { data } = res.data
          this.tableData = data
          let { total } = this.$tools.getPaginationInfo(res.headers)
          this.total = total
        })
        .finally(() => (this.loading = false))
    },
    onExportData() {
      this.$_axios2.post('api/common/export/teacher', this.search).then(res => {
        this.$tools.download('教师信息', res.data)
        this.$message.success('导出成功')
      })
    },
    // 搜索按钮被点击
    onSearch(val) {
      this.page = 1
      this.search = val
      this.getData()
    },
    // 表格后面操作被点击
    tableHandle(row, text, index) {
      this.$router.push({ path: './details', query: { id: row.id } })
    }
  }
}
</script>

<style scoped lang="scss">
.list {
  padding: 24rem 0 0 32rem;
  .search {
    .el-input,
    .el-select {
      width: 240rem;
      margin-right: 24rem;
    }
  }
  .pagination {
    margin-top: 50rem;
  }

}
</style>
